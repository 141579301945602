<template>
  <div class="staff-members">
    <lenon-list-page
      title="Staff Payments"
      :columns="columns"
      :rows="payments"
      :table-loading="tableLoading"
      :show-create="false"
      :show-refresh="false"
      :show-search="true"
      :show-details="true"
      :show-profile-photo="true"
      enable-multi-select
      search-placeholder="Type to search..."
      @onRowSelect="(items)=>{selectedStaffMembers = items}"
    >
      <template slot="right-extra-header-actions">
        <div
          v-responsive="largeScreen"
          class="d-flex align-self-center align-items-start"
          style="margin-bottom: -20px"
        >
          <lenon-month-year-picker
            v-model="selectedMonth"
            label="Select Month"
            :show-label="false"
            class="mr-1"
            @input="monthChanged"
          />
          <lenon-button
            variant="outline-primary"
            class="mr-2"
            icon="FileTextIcon"
            label="Payslips"
            tool-tip-text="Generate staff payslips."
            @onClick="showPaySlipModal()"
          />
          <lenon-button
            variant="outline-primary"
            class="mr-2"
            icon="FileTextIcon"
            label="PAYE & SSNIT Report"
            tool-tip-text="Generate PAYE & SSNIT report for remittance to the appropriate authorities."
            @onClick="showPayeSsnitModal()"
          />
          <lenon-button
            icon="UserCheckIcon"
            label="Disburse"
            tool-tip-text="Disburse salaries to selected staff members"
            class=""
            @onClick="showPasswordConfirmationModal()"
          />
        </div>
        <div
          v-responsive="smallScreen"
          class="d-flex align-self-center align-items-start"
        >
          <lenon-dropdown left>
            <lenon-dropdown-item
              icon="FileTextIcon"
              text="Payslips"
              @click="showPaySlipModal()"
            />
            <lenon-dropdown-item
              icon="FileTextIcon"
              text="PAYE & SSNIT Report"
              @click="showPayeSsnitModal()"
            />
            <lenon-dropdown-item
              icon="UserCheckIcon"
              text="Disburse"
              @click="showPasswordConfirmationModal()"
            />
          </lenon-dropdown>
        </div>
      </template>
      <template slot="table-header">
        <div
          v-responsive="smallScreen"
          class="col-md-6"
        >
          <lenon-month-year-picker
            v-model="selectedMonth"
            label="Select Month"
            :show-label="false"
            class="mb-1"
            @input="monthChanged"
          />
        </div>
        <!--        <div class="row ">-->
        <div class="col-12 mb-1 text-danger">
          <strong>NB: You can select any month to preview staff salary payments, default is the current
            month.</strong>
        </div>
        <!--        </div>-->
        <div class="col-12 mb-1">
          <div class="row">
            <div class="col-md-3 d-flex flex-row">
              <strong>Total Payments:
                <b-badge
                  variant="light-success"
                  style="font-size: 15px;"
                >
                  <small>{{ $store.getters['auth/currency'] }}</small>+{{ totalPayment | currency }}
                </b-badge>
              </strong>
            </div>
            <div class="col-md-3 d-flex flex-row">
              <strong>Total Tax:
                <b-badge
                  variant="light-danger"
                  style="font-size: 15px;"
                >
                  <small>{{ $store.getters['auth/currency'] }}</small>-{{ totalPaye | currency }}
                </b-badge>
              </strong>
            </div>
            <div class="col-md-3 d-flex flex-row">
              <strong>Total SSNIT (T1-5.5%):
                <b-badge
                  variant="light-danger"
                  style="font-size: 15px;"
                >
                  <small>{{ $store.getters['auth/currency'] }}</small>-{{ totalT1 | currency }}
                </b-badge>
              </strong>
            </div>
            <div class="col-md-3 d-flex flex-row">
              <strong>Total SSNIT (T2-13%):
                <b-badge
                  variant="light-primary"
                  style="font-size: 15px;"
                >
                  <small>{{ $store.getters['auth/currency'] }}</small>{{ totalT2 | currency }}
                </b-badge>
              </strong>
            </div>
          </div>
        </div>
      </template>
      <template #name="{row}">
        {{ row.item.first_name }} {{ row.item.last_name }}
      </template>
      <template #base_salary="{row}">
        <div v-if="row.item.base_salary">
          {{ row.item.base_salary | currency }}
        </div>
        <b-badge
          v-else
          variant="light-success"
        >
          N/A
        </b-badge>
      </template>
      <template #net="{row}">
        <div v-if="row.item.net">
          {{ row.item.net | currency }}
        </div>
        <b-badge
          v-else
          variant="light-success"
        >
          N/A
        </b-badge>
      </template>
      <template #type="{row}">
        <b-badge
          v-if="row.item.type"
          :variant="row.item.type==='cash'?'light-success':'light-primary'"
        >
          {{ row.item.type.toUpperCase() }}
        </b-badge>
        <b-badge
          v-else
          variant="light-success"
        >
          N/A
        </b-badge>
      </template>
      <template #paye="{row}">
        <div v-if="row.item.paye">
          {{ row.item.paye | currency }}
        </div>
        <b-badge
          v-else
          variant="light-success"
        >
          N/A
        </b-badge>
      </template>
      <template #t1="{row}">
        <div v-if="row.item.t1">
          {{ row.item.t1 | currency }}
        </div>
        <b-badge
          v-else
          variant="light-success"
        >
          N/A
        </b-badge>
      </template>
      <template #t2="{row}">
        <div v-if="row.item.t2">
          {{ row.item.t2 | currency }}
        </div>
        <b-badge
          v-else
          variant="light-success"
        >
          N/A
        </b-badge>
      </template>
      <template #paid="{row}">
        <b-badge
          v-if="row.item.payment_id"
          variant="light-success"
        >
          {{ row.item.status.toUpperCase() }}
        </b-badge>
        <b-badge
          v-else
          variant="light-danger"
        >
          UNPAID
        </b-badge>
      </template>
      <template #actions="{row}">
        <lenon-button
          icon-only
          variant="flat-primary"
          :icon="row.detailsShowing?'EyeOffIcon':'EyeIcon'"
          tool-tip-text="View staff details"
          @onClick="row.toggleDetails"
        />
      </template>
    </lenon-list-page>
    <lenon-modal
      :show="payslipModalOpened"
      :show-overlay="generatingPayslip"
      :title="`Generate Payslips for ${selectedMonth}`"
      :ok-text="`Generate for (${selectedStaffMembers.length}) member(s)`"
      @onClose="closePaySlipModal()"
      @onOk="generatePayslips()"
    >
      <b-form-group>
        <b-form-checkbox
          v-model="sendToStaffEmail"
        >
          Checking this option will generate a payslip for each staff member and send to their emails.
          If not, payslips will be generated and downloaded for your use.
        </b-form-checkbox>
      </b-form-group>
    </lenon-modal>
    <lenon-modal
      :show="payeSsnitModalOpened"
      :show-overlay="generatingPaye"
      :title="`Generate PAYE & SSNIT Report for ${selectedMonth}`"
      :ok-text="`Generate for (${selectedStaffMembers.length}) member(s)`"
      @onClose="closePayeSsnitModal()"
      @onOk="generateSPReport()"
    >
      <p>NB: Proceeding will generate and download a report that you can use as a reference to remit both PAYE tax and SSNIT contributions for the selected staff members.</p>
    </lenon-modal>
    <lenon-modal
      :show="passwordConfirmationModalOpened"
      :show-overlay="sendingOtp"
      title="Password Confirmation"
      ok-text="Send OTP"
      :ok-disabled="!currentPassword"
      @onClose="closePasswordConfirmationModal()"
      @onOk="sendOTP()"
    >
      <p>Enter your current password and we will send an OTP to your phone.
        You will need this OTP to proceed with disbursement.</p>
      <lenon-input
        v-model="currentPassword"
        placeholder="Please enter your password"
        type="password"
      />
    </lenon-modal>
    <lenon-modal
      :show="disbursementModalOpened"
      :show-overlay="disbursingSalaries"
      title="Disburse Salaries"
      :ok-disabled="!otp"
      :ok-text="`Disburse to (${selectedStaffMembers.length}) member(s)`"
      @onClose="closeDisburseModal()"
      @onOk="disburseSalaries()"
    >
      <p>Please note that you can only disburse salaries once in a month,
        if you try to disburse more than once, the operation would simply be ignored.</p>
      <lenon-input
        v-model="otp"
        placeholder="Please enter OTP"
        type="password"
      />
      <b-form-group>
        <b-form-checkbox
          v-model="isCashPayment"
        >
          Cash Payment (Actual money will not be disbursed) - Uncheck this option if you want Lenon to automatically transfer money from your PAYSTACK balance.
        </b-form-checkbox>
      </b-form-group>
      <div
        v-if="!isCashPayment"
        class="row"
      >
        <div class="col-12">
          <strong>Transaction cost: </strong> <span><b-badge variant="light-danger">{{ $store.getters["auth/currency"] }}-{{ totalPayment | currency }}</b-badge></span>
        </div>
        <div class="col-12">
          <strong>Current balance: </strong> <span><b-badge variant="light-success">{{ $store.getters["auth/currency"] }}+{{ balance | currency }}</b-badge></span>
        </div>
        <div class="col-12 text-danger text-bold mt-1">
          <small>Please make sure your balance is more than transaction cost.</small>
        </div>
      </div>
    </lenon-modal>
  </div>
</template>

<script>
import {
  BBadge, BFormCheckbox, VBTooltip, BFormGroup,
} from 'bootstrap-vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import { GENERATE_SSNIT_PAYE_REPORT_Q, STAFF_PAYMENTS_Q, GENERATE_PAYSLIPS_Q } from '@/graphql/queries'
import { SEND_OTP_M, DISBURSE_SALARIES_M } from '@/graphql/mutations'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import LenonMonthYearPicker from '@/lenon/components/LenonMonthYearPicker.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import LenonDropdownItem from '@/lenon/components/LenonDropdownItem.vue'

export default {
  name: 'StaffMemberList',
  components: {
    LenonModal,
    LenonInput,
    LenonMonthYearPicker,
    LenonListPage,
    LenonButton,
    BBadge,
    BFormCheckbox,
    BFormGroup,
    LenonDropdown,
    LenonDropdownItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [showToast],
  data() {
    return {
      staffPositionModalOpened: false,
      staffMemberModalOpened: false,
      departmentModalOpened: false,
      tableLoading: false,

      passwordConfirmationModalOpened: false,
      sendingOtp: false,
      currentPassword: null,

      disbursementModalOpened: false,
      disbursingSalaries: false,
      isCashPayment: true,
      otp: null,

      selectedMonth: null,
      selectedStaffMembers: [],

      payeSsnitModalOpened: false,
      generatingPaye: false,

      payslipModalOpened: false,
      generatingPayslip: false,
      sendToStaffEmail: false,

      totalPayment: 0.0,
      totalPaye: 0.0,
      totalT1: 0.0,
      totalT2: 0.0,

      columns: [
        {
          key: 'multiselect',
          label: '',
        },
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'photo',
          label: 'Staff Photo',
        },
        {
          key: 'name',
          label: 'Staff Full Name',
        },
        {
          key: 'staff_id',
          label: 'Staff ID',
        },
        {
          key: 'base_salary',
          label: `BASE SALARY (${this.$store.getters['auth/currency']})`,
        },
        {
          key: 'net',
          label: `NET SALARY (${this.$store.getters['auth/currency']})`,
        },
        {
          key: 'paye',
          label: `TAX/PAYE (${this.$store.getters['auth/currency']})`,
        },
        {
          key: 't1',
          label: `SSNIT TIER-1 (${this.$store.getters['auth/currency']})`,
        },
        {
          key: 't2',
          label: `SSNIT TIER-2 (${this.$store.getters['auth/currency']})`,
        },
        {
          key: 'paid',
          label: 'Payment Status',
        },
        {
          key: 'type',
          label: 'Payment Mode',
        },
      ],
    }
  },
  computed: {
    smallScreen() {
      return this.$store.getters['app/smallScreen']
    },
    largeScreen() {
      return this.$store.getters['app/largeScreen']
    },
    balance() {
      return 0
    },
    payments() {
      return this.$store.getters['staff/payments']
    },
    paymentsSettings() {
      return this.$store.getters['staff/paymentsSettings']
    },
  },
  watch: {
    payments(val) {
      this.totalPayment = 0.00
      this.totalPaye = 0.00
      this.totalT1 = 0.00
      this.totalT2 = 0.00
      for (let i = 0; i < val.length; i++) {
        if (val[i]) {
          this.totalPayment += val[i].net
          this.totalPaye += val[i].paye
          this.totalT1 += val[i].t1
          this.totalT2 += val[i].t2
        }
      }
    },
    selectedMonth(selectedMonth) {
      if (selectedMonth) {
        this.$store.commit('staff/setPaymentSettings', selectedMonth)
      }
    },
  },
  mounted() {
    const currentDate = new Date()
    let currentMonth = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}`
    if (this.paymentsSettings.selectedMonth) {
      currentMonth = this.paymentsSettings.selectedMonth
    }
    this.selectedMonth = currentMonth
  },
  methods: {
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'department':
          this.departmentModalOpened = false
          break
        case 'member':
          this.staffMemberModalOpened = false
          break
        case 'position':
          this.staffPositionModalOpened = false
      }
    },
    monthChanged(month) {
      this.fetchStaffPayments()
    },
    closePaySlipModal() {
      this.payslipModalOpened = false
    },
    showPaySlipModal() {
      if (this.selectedStaffMembers.length > 0) {
        this.payslipModalOpened = true
      } else {
        this.showInfo('Select at least one staff member')
      }
    },
    closePayeSsnitModal() {
      this.payeSsnitModalOpened = false
    },
    showPayeSsnitModal() {
      if (this.selectedStaffMembers.length > 0) {
        this.payeSsnitModalOpened = true
      } else {
        this.showInfo('Select at least one staff member')
      }
    },
    showDisburseModal() {
      this.disbursementModalOpened = true
    },
    closeDisburseModal() {
      this.disbursementModalOpened = false
    },
    showPasswordConfirmationModal() {
      if (this.selectedStaffMembers.length > 0) {
        this.passwordConfirmationModalOpened = true
      } else {
        this.showInfo('Select at least one staff member')
      }
    },
    closePasswordConfirmationModal() {
      this.passwordConfirmationModalOpened = false
    },
    showDepartmentModal() {
      this.departmentModalOpened = true
    },
    showStaffMemberModal() {
      this.staffMemberModalOpened = true
    },
    showStaffPositionModal() {
      this.staffPositionModalOpened = true
    },
    fetchStaffPayments() {
      this.tableLoading = true
      this.$apollo.query({ query: STAFF_PAYMENTS_Q, variables: { date: this.selectedMonth } })
        .then(res => {
          this.$store.commit('staff/setStaffPayments', res.data.staffPayments)
        }).finally(() => {
          this.tableLoading = false
        })
    },
    generatePayslips() {
      this.generatingPayslip = true
      this.$apollo.query({ query: GENERATE_PAYSLIPS_Q, variables: { date: this.selectedMonth, staff_members: this.selectedStaffMembers.map(st => st.id), send_to_staff: this.sendToStaffEmail } })
        .then(res => {
          this.closePaySlipModal()
          if (res.data && res.data.generatePayslips) {
            const link = document.createElement('a')
            link.href = `${process.env.VUE_APP_STORAGE_PATH}/${res.data.generatePayslips.path}`
            link.target = '_blank'
            link.setAttribute('download', res.data.generatePayslips.name) // or any other extension
            document.body.appendChild(link)
            link.click()
          }
          if (res.data && !res.data.generatePayslips) {
            if (this.sendToStaffEmail) {
              this.showSuccess('Payslips are being sent to income')
              this.sendToStaffEmail = false
            } else {
              this.showError('Could not generate payslips, make sure at least 1 selected staff member has been paid for the selected month.')
            }
          }
        }).finally(() => {
          this.generatingPayslip = false
        })
    },
    generateSPReport() {
      this.generatingPaye = true
      this.$apollo.query({ query: GENERATE_SSNIT_PAYE_REPORT_Q, variables: { date: this.selectedMonth, staff_members: this.selectedStaffMembers.map(st => st.id) } })
        .then(res => {
          if (res.data && res.data.generateSsnitPayeReport) {
            this.closePayeSsnitModal()
            const link = document.createElement('a')
            link.href = `${process.env.VUE_APP_STORAGE_PATH}/${res.data.generateSsnitPayeReport.path}`
            link.target = '_blank'
            link.setAttribute('download', res.data.generateSsnitPayeReport.name) // or any other extension
            document.body.appendChild(link)
            link.click()
          }
        }).finally(() => {
          this.generatingPaye = false
        })
    },
    disburseSalaries() {
      this.disbursingSalaries = true
      const inputData = {
        otp: this.otp, is_cash_payment: this.isCashPayment, date: this.selectedMonth, staff_members: this.selectedStaffMembers.map(st => st.id),
      }
      this.$apollo.mutate({ mutation: DISBURSE_SALARIES_M, variables: { input: inputData } })
        .then(res => {
          if (res.data && res.data.disburseSalaries) {
            this.currentPassword = null
            this.showSuccess(this.isCashPayment ? `Payments recorded for ${this.selectedMonth}` : `Payments have been initiated for the ${this.selectedMonth}, staff members will be notified accordingly.`)
            this.disbursementModalOpened = false
            this.fetchStaffPayments()
          } else {
            this.showError('Please make sure your otp is correct and has not expired.')
          }
        }).catch(err => {
          this.showError('Sending disbursement request failed')
        }).finally(() => {
          this.disbursingSalaries = false
        })
    },
    sendOTP() {
      this.sendingOtp = true
      this.$apollo.mutate({ mutation: SEND_OTP_M, variables: { password: this.currentPassword } })
        .then(res => {
          if (res.data && res.data.sendOtp) {
            this.currentPassword = null
            this.showSuccess('An OTP has been sent to your phone number')
            this.closePasswordConfirmationModal()
            this.showDisburseModal()
          } else {
            this.showError('Please make sure you entered a correct password.')
          }
        }).catch(err => {
          this.showError('Sending otp failed')
        }).finally(() => {
          this.sendingOtp = false
        })
    },
  },
}
</script>
